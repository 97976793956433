<template>
  <div class='border-t bg-white flex flex-col py-12'>
    <div class='layout-container w-full bg-white flex flex-col lg:flex-row gap-y-8 justify-between px-4 lg:px-0 text-xs lg:text-sm text-gray-700'>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>사단법인 대한면역학회</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Contact Number</span>
          +82-02-797-0975
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Email</span> 
          kai1974info@gmail.com
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Fax</span> 
          02-797-0976
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Address</span> 
          [06130] 서울특별시 강남구 테헤란로7길 22 (역삼동 635-4) 한국과학기술회관 신관 7층 701호
        </div>
      </div>
      <div>
        <div class='font-semibold text-sm lg:text-base text-gray-900'>2022년 대한면역학회 춘계학술대회 사무국</div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Contact Number</span>
          +82-02-6949-5333
        </div>
        <div class='mt-2 text-sm'>
          <span class='uppercase inline-block opacity-70 w-36 '>Email</span> 
          info@ksimm.org
        </div>
      </div>
      <div>
        <img :src='eventConfigLogoImageUrl'
             class='h-10 lg:h-14 cursor-pointer mb-6'
             @click='goToHome'/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'KaiSpring2022LayoutFooter',
  computed: {
    ...mapGetters('events', [
      'eventConfigLogoImageUrl',
    ]),
  },
  methods: {
    goToHome () {
      this.$router.push({name: 'Home'}).catch(() => {})
    },
  }
}
</script>
